export const MAP_AIS_ID = 'map-ais-id';
export const MAP_PERIODS_ID = 'map-periods-id';
export const MAP_VISIT = 'map-visit-id';

// MAP VARIABLES
export const CENTER = [0, 0];
export const MIN_ZOOM = 1;
export const MAX_ZOOM = 19;
export const INITIAL_ZOOM = 1;
export const INITIAL_ZOOM_VISITS = 10;

export const INITIAL_BOUNDS_KPIS = [-180, -90, 180, 90];

export const MIN_ZOOM_BOLLARDS_BERTHS = 16;

export const STATUS_VIS = 'STATUS';
export const TYPE_VIS = 'TYPE';
export const OPERATIONS_VIS = 'OPERATIONS';
