var constants;

if (process.env.NODE_ENV === 'pro') {
	constants = {
		NODE_ENV: 'pro',
		MATOMO_URL: 'https://analytics.prodevelop-cdn.com/',
		MATOMO_ID: '7'
	};
} else if (process.env.NODE_ENV === 'pre') {
	constants = {
		NODE_ENV: 'pre',
		MATOMO_URL: 'https://analytics.prodevelop-cdn.com/',
		MATOMO_ID: '6'
	};
} else {
	constants = {
		NODE_ENV: 'dev',
		MATOMO_URL: 'https://analytics.prodevelop-cdn.com/',
		MATOMO_ID: '6'
	};
}

export default constants;
