<template>
	<div class="appMenuBottomBar">
		<pui-menu-icon-bottom-bar-custom-btn
			v-show="showBtnAlert"
			icon="fa-sharp fa-regular fa-bell"
			:buttonTitle="$t('menu.alert')"
			:action="customAction"
			:badgeContent="badgeCount"
			:badge="visibleBadge"
		>
		</pui-menu-icon-bottom-bar-custom-btn>
	</div>
</template>

<script>
export default {
	name: 'AppMenuBottomBar',
	data: () => ({
		showBtnAlert: true,
		availableTasksCounter: 0
	}),
	beforeMount() {
		this.getBadgeCount();
		this.showBtnAlert = this.$store.getters.getSession.functionalities.includes('READ_ALERT');
	},
	mounted() {
		this.showBtnAlert = this.$store.getters.getSession.functionalities.includes('READ_ALERT');
	},
	created() {},
	beforeDestroy() {},
	methods: {
		customAction() {
			const params = {
				router: this.$router,
				url: '/alert',
				params: null,
				query: {},
				replace: true
			};

			if (this.$store.getters.getSession.functionalities.includes('READ_ALERT')) {
				this.$store.dispatch('puiRouterGoToUrl', params);
			}
		},
		getBadgeCount() {
			const opts = {
				model: 'alert',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'portid',
							op: 'eq',
							data: window.localStorage.getItem('workingPortsId')
						},
						{
							field: 'read',
							op: 'eq',
							data: false
						}
					]
				},
				order: [
					{
						column: 'timestamp',
						direction: 'desc'
					}
				]
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data) {
					this.availableTasksCounter = response.data.totalRecords;
				}
			});
		}
	},
	computed: {
		badgeCount() {
			return this.availableTasksCounter == 0 ? '' : this.availableTasksCounter.toString();
		},
		visibleBadge() {
			return this.availableTasksCounter > 0;
		}
	}
};
</script>
