import Vue from 'vue';

import Pui9Login from 'pui9-login';
import Pui9LoginCheckerMixinFactory from 'pui9-login/src/mixins/PuiLoginCheckerMixin';
import SessionClosedMixin from '@/mixins/SessionClosedMixin';
import Pui9Menu from 'pui9-menu';
import Pui9Notify from 'pui9-notifications';
import Pui9HttpRequests from 'pui9-requests';
import Pui9WebSockets from 'pui9-websockets';
import Pui9Datatables from 'pui9-datatables';
import Pui9Components from 'pui9-components';
import Pui9Base from 'pui9-base';
import Pui9Admin from 'pui9-admin';
import Pui9Dashboard from 'pui9-dashboard';
import Pui9Alerts from 'pui9-alerts';
import Pui9Documents from 'pui9-documents';
import Pui9Maps, { addPui9MapsI18n } from 'pui9-maps';

import store from './store/store';
import router from './router/router';
import i18n from './i18n/i18n';
import vuetify from './plugins/vuetify';
import puiEventsBus from './bus';
import beforeRun from './beforeRun';

import App from './App.vue';

import '@fortawesome/fontawesome-pro/css/all.css';
import 'vuetify/dist/vuetify.min.css';

import 'pui9-styles/pui9.css';
import 'pui9-login/dist/pui9-login.css';
import 'pui9-datatables/dist/pui9-datatables.css';
import 'pui9-base/dist/pui9-base.css';
import 'pui9-dashboard/dist/pui9-dashboard.css';
import 'pui9-maps/dist/pui9-maps.css';

import './styles/app.pcss';
import './styles/operations.pcss';
import './styles/rappid.min.pcss';

import { initMatomo } from './plugins/matomo';
import ECharts from 'vue-echarts';
import AppMenuBottomBar from './components/appmenubottombar/AppMenuBottomBar';
import CustomFieldsModals from '@/components/customfields/CustomFieldsModals';
import CustomFieldsGenerateForm from '@/components/customfields/CustomFieldsGenerateForm';
Vue.config.productionTip = false;

function defineGlobalPlugins() {
	Vue.use(Pui9Login);
	Vue.use(Pui9Menu);
	Vue.use(Pui9Notify);
	Vue.use(Pui9Datatables);
	Vue.use(Pui9Components);
	Vue.use(Pui9Base);
	Vue.use(Pui9Admin);
	Vue.use(Pui9Dashboard);
	Vue.use(Pui9Alerts);
	Vue.use(Pui9Documents);
	Vue.use(Pui9Maps, process.env.VUE_APP_PUI9_PUI9MAPS_ASSETS);

	initMatomo();
	Vue.component('v-chart', ECharts);
	Vue.component('AppMenuBottomBar', AppMenuBottomBar);
	Vue.component(CustomFieldsModals.name, CustomFieldsModals);
	Vue.component(CustomFieldsGenerateForm.name, CustomFieldsGenerateForm);

	Object.defineProperty(Vue.prototype, '$puiRequests', { value: Pui9HttpRequests });
	Object.defineProperty(Vue.prototype, '$puiWebSockets', { value: Pui9WebSockets });
	Object.defineProperty(Vue.prototype, '$puiEvents', { value: puiEventsBus });
	Object.defineProperty(Vue.prototype, '$puiI18n', { value: i18n });
}

function defineGlobalMixins() {
	Vue.mixin(Pui9LoginCheckerMixinFactory(store));
	Vue.mixin(SessionClosedMixin);

	// FIXME: Only for commercial use, remove in future!!!
	Vue.mixin({
		computed: {
			changeLeftBarLogoOnPuiModelsLoadedAndSaved() {
				return this.$store && this.$store.state.modelsLoadedAndSaved;
			}
		},
		watch: {
			changeLeftBarLogoOnPuiModelsLoadedAndSaved(modelsLoadedAndSaved) {
				if (!modelsLoadedAndSaved) {
					return;
				}
				if (this.$store.getters.getUser === 'huelva-operaciones') {
					// specify the port logo image
					const logoElement = document.getElementsByClassName('puimenu-leftBar__logo-img')[0];
					if (logoElement && !logoElement.className.includes('huelva')) {
						logoElement.setAttribute('class', 'puimenu-leftBar__logo-img puimenu-leftBar__logo-img--huelva');
					}
				} else if (this.$store.getters.getUser === 'operations-malta') {
					// specify the port logo image
					const logoElement = document.getElementsByClassName('puimenu-leftBar__logo-img')[0];
					if (logoElement && !logoElement.className.includes('malta')) {
						logoElement.setAttribute('class', 'puimenu-leftBar__logo-img puimenu-leftBar__logo-img--malta');
					}
				}
			}
		}
	});
}

function storeTimezoneHeader() {
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	store.commit('setHttpRequestHeaders', { timezone });
}

function setLanguage() {
	i18n.locale = window.localStorage.getItem('pui9_lang') || store.state.global.defaultAppLang;
}

function setCustomStyles() {
	document.documentElement.style.setProperty('--main-150', '#014EFA');
	document.documentElement.style.setProperty('--main-200', '#014EFA');
	document.documentElement.style.setProperty('--primarycolor', '#014EFA');
	document.documentElement.style.setProperty('--inputfocuscolor', '#014EFA');
}

(async () => {
	puiEventsBus.$on('onPuiChangedLang', (lang) => {
		i18n.locale = lang;
	});

	Pui9HttpRequests.setStore(store);

	defineGlobalPlugins();
	defineGlobalMixins();

	storeTimezoneHeader();
	setLanguage();
	setCustomStyles();

	await beforeRun();

	const vm = new Vue({
		vuetify,
		store,
		router,
		i18n,
		render: (h) => h(App)
	});

	addPui9MapsI18n(vm);

	store.$app = vm;

	vm.$mount('#app');
})();
