export default {
	getAllConfigPortCompany({ getters, state }) {
		const allConfig = getters.getAllConfigPortCompany;
		if (allConfig) {
			return allConfig;
		}

		const {
			$app: { $puiRequests }
		} = this;

		const opts = { model: 'configportcompany' };
		$puiRequests.postRequest('/puisearch', opts, (response) => {
			state.operations.configPortCompany = response.data.data;
		});
	},
	refreshAllConfigPortCompany({ getters, state }) {
		const {
			$app: { $puiRequests }
		} = this;

		const opts = { model: 'configportcompany' };
		$puiRequests.postRequest('/puisearch', opts, (response) => {
			state.operations.configPortCompany = response.data.data;
		});
	},
	getAllOperationsProfileCompany({ state }, params) {
		const {
			$app: { $puiRequests }
		} = this;

		//TODO: get all by language
		const opts = { model: 'operationsprofilecompany' };
		$puiRequests.postRequest('/puisearch', opts, (response) => {
			if (response && response.data) {
				if (!state[params.model]) {
					state[params.model] = {};
				}
				state[params.model][params.requestModel] = response.data.data;
			}
		});
	},
	setSubLayerGroupActive({ commit }, layer) {
		commit('setSubLayerGroupActive', layer);
	}
};
