const fechaHoy = new Date();
fechaHoy.setHours(0, 0, 0);
fechaHoy.setMilliseconds(0);

// Past dates
const fechaPastIni = new Date(fechaHoy);
fechaPastIni.setDate(fechaPastIni.getDate() - 1);
const fechaPastEnd = new Date();
fechaPastEnd.setHours(23, 59, 0);
fechaPastEnd.setMilliseconds(0);

// Future dates
const fechaFutureEnd = new Date(fechaHoy);
fechaFutureEnd.setDate(fechaFutureEnd.getDate() + 1);
fechaFutureEnd.setHours(23, 59, 0);
fechaFutureEnd.setMilliseconds(0);

// Resquested and Planning dates
const fechaReqPlEnd = new Date(fechaHoy);
fechaReqPlEnd.setDate(fechaReqPlEnd.getDate() + 3);
fechaReqPlEnd.setHours(23, 59, 0);
fechaReqPlEnd.setMilliseconds(0);

export const FEC_TODAY = fechaHoy.toISOString().split('.')[0] + 'Z';
export const FEC_PAST_INI = fechaPastIni.toISOString().split('.')[0] + 'Z';
export const FEC_PAST_END = fechaPastEnd.toISOString().split('.')[0] + 'Z';
export const FEC_FUTURE_END = fechaFutureEnd.toISOString().split('.')[0] + 'Z';
export const FEC_REQPL_INI = fechaReqPlEnd.toISOString().split('.')[0] + 'Z';
