import pkg from '../../package.json';
import i18n from '@/i18n/i18n';
import { TAB_KEY_ENTRY } from '@/components/gisweb/utils/tabKeysConstants.js';
import { SETTINGS_PANEL_ID, REQUESTEDS_PANEL_ID } from '@/lib/variables/panels';
import { TYPE_VIS, STATUS_VIS, INITIAL_BOUNDS_KPIS } from '@/components/operations/map/sections/map/subcomponents/mapVariables';
import {
	AIS_VESSEL_GROUP_ID,
	STOP_PREVISION_GROUP_ID,
	STOP_ACCEPTED_LAYER_ID,
	STOP_PLANNED_LAYER_ID,
	STOP_AUTHORIZED_LAYER_ID,
	STOP_CONFIRMED_LAYER_ID,
	STOP_INITIATED_LAYER_ID,
	FILTER_AIS_VISIT_LAYER_ID,
	FILTER_SERVICE_LAYER_ID,
	FILTER_OTHER_VESSEL_LAYER_ID,
	FILTER_HIGH_FREQUENCY_ID,
	CEP_ZONES_ID
} from '@/components/operations/map/constants/layers';

import {
	FEC_TODAY,
	FEC_PAST_INI,
	FEC_PAST_END,
	FEC_FUTURE_END,
	FEC_REQPL_INI
} from '@/components/operations/map/sections/infoperiods/InitialInstantDates';

export default {
	global: {
		appVersion: pkg.version,
		appDefaultEntryPoint: 'home',
		baseUrl: process.env.VUE_APP_BASE_SERVER_URL,
		webSocketsEnabled: true,
		webSocketsUrl: process.env.VUE_APP_PUI9_WEBSOCKETS_URL,
		appHeaderMapHeightInPx: 52,
		langContainerActionHeader: false,
		containerActionHeader: true,
		appMenuBottomBar: true,
		singleItemAutoselectOnRequired: true
	},
	login: {
		forgetPassword: true,
		afterLoginFunction: null,
		afterLogoutFunction: null,
		mixinExecuted: false
	},
	menu: {
		homeBtn: true,
		searchBtn: false,
		infoBtn: false,
		notificationsBtn: false,
		helpBtn: false,
		keepExpanded: true,
		items: null,
		showLeftBar: true
	},
	grid: {
		showRowActions: false
	},
	form: {
		showSaveAndUpdate: true,
		showSaveAndNew: true,
		showPrevNextBtns: true
	},
	operations: {
		etcSafetyMargin: null,
		configPortCompany: null,
		shipSelectedId: 0, //Default
		portAuthorityId: 0, //Default
		portlocode: 0
	},
	masters: {
		userPortsId: []
	},
	gisweb: {
		tabmodel: TAB_KEY_ENTRY
	},
	mapconfig: {
		userQuaysId: [],
		berths: [],
		bollards: {},
		mapPortsExtents: null,
		mapAutorityExtent: null,
		stopsTypeValues: null,
		stopsOperationsValues: {
			vesselvisit: {
				color: '#f8c5be',
				coloroutline: '#e8311a',
				text: i18n.t('legend.operationsVis.vesselvisit').toUpperCase(),
				shape: 'point'
			},
			isPilot: {
				color: '#dcd0ed',
				coloroutline: '#7b45b8',
				text: i18n.t('legend.operationsVis.pilot').toUpperCase(),
				shape: 'point'
			},
			isTug: {
				color: '#ffd0ec',
				coloroutline: '#db49a0',
				text: i18n.t('legend.operationsVis.tug').toUpperCase(),
				shape: 'point'
			},
			otherVesselServices: {
				color: '#bcf0ce',
				coloroutline: '#24a54e',
				text: i18n.t('legend.operationsVis.othervesselsservices').toUpperCase(),
				shape: 'point'
			},
			otherVessel: {
				color: '#bacff8',
				coloroutline: '#2535c8',
				text: i18n.t('legend.operationsVis.othervessels').toUpperCase(),
				shape: 'point'
			},
			highFrequency: {
				color: '#ECA07B',
				coloroutline: '#FF7800',
				text: i18n.t('legend.operationsVis.highFrequency').toUpperCase(),
				shape: 'point'
			}
		},
		portAutorityHaveStatusPl: false,
		defaultBackgroundColor: null, // Get from pui_variable
		defaultStrokeColor: null // Get from pui_variable
	},

	berthPlanner: {
		BERTHPLANNER_SELECTORS: null,
		WEATHER_FORECAST_IN_BP: null,
		configuration: {
			selectedDateModel: {
				startDate: null,
				endDate: null
			},
			currentLocation: {
				currentPort: {},
				currentDock: {},
				currentBerth: {}
			},
			searchText: null,
			vesselDialogModel: null,
			berthBlockDialogModel: null
		}
	},

	// Nuevo operations
	stopsStatusValues: null,
	visitsStatusValues: null,
	weatherKey: null,
	mappanelais: {
		map: null,
		mapAisIsLoaded: false,
		mapStyleVisualization: TYPE_VIS,
		mapPortVisualization: 0,
		mapFitPortExtent: null,

		mapPortLocodeSelected: null,

		aisPortCallNumbers: [],

		stopsFeaturesAccepted: [],
		stopsFeaturesPlanned: [],
		stopsFeaturesAuthorized: [],
		stopsFeaturesConfirmed: [],
		stopsFeaturesInitiated: [],

		searchingtext: '',

		/**
		 * Panels
		 */
		activePanel: SETTINGS_PANEL_ID,
		activePanelOptions: null,
		dynamicPanels: [],
		dynamicPanelsId: [],

		/**
		 * Map Settings
		 */

		map: {
			legendExpanded: false,
			zoom: null,
			bbox: INITIAL_BOUNDS_KPIS
		},

		layers: {
			[AIS_VESSEL_GROUP_ID]: {
				identifier: AIS_VESSEL_GROUP_ID,
				opened: false,
				active: true,
				group: true,
				filtered: false,
				[FILTER_AIS_VISIT_LAYER_ID]: true,
				[FILTER_SERVICE_LAYER_ID]: true,
				[FILTER_OTHER_VESSEL_LAYER_ID]: true,
				[FILTER_HIGH_FREQUENCY_ID]: true
			},
			[STOP_PREVISION_GROUP_ID]: {
				identifier: STOP_PREVISION_GROUP_ID,
				opened: false,
				active: false,
				group: true,
				filtered: false,
				[STOP_ACCEPTED_LAYER_ID]: false,
				[STOP_PLANNED_LAYER_ID]: false,
				[STOP_AUTHORIZED_LAYER_ID]: false,
				[STOP_CONFIRMED_LAYER_ID]: false,
				[STOP_INITIATED_LAYER_ID]: false
			},
			[CEP_ZONES_ID]: {
				identifier: CEP_ZONES_ID,
				opened: false,
				active: false,
				group: false,
				filtered: false
			}
		}
	},

	// Map panel situacion de buques
	mappanelperiods: {
		map: null,
		mapPeriodsIsLoaded: false,
		mapStyleVisualization: STATUS_VIS,
		mapPortVisualization: 0,
		mapFitPortExtent: null,

		// Stops sin procesar
		stopsFeaturesInstantInitially: [],

		// Past and Future
		stopsFeaturesInstant: [],

		// Planning
		stopsFeaturesInstantPL: [],
		stopsFeaturesInstantModifiedPL: [],
		stopsFeaturesInstantWithModifiedPL: [],
		stopsFeaturesInstantRemovedPL: [],

		// Authorization
		stopsFeaturesInstantInitiallyAU: [],
		stopsFeaturesInstantAU: [],
		stopsFeaturesInstantModifiedAU: [],
		stopsFeaturesInstantWithModifiedAU: [],
		stopsFeaturesInstantRemovedAU: [],

		// Processiong vessels
		processVesselsRunning: false,

		// Search text panels
		searchingtextpastpanel: '',
		searchingtextfuturepanel: '',
		searchingtextrequestedspanel: '',
		searchingtextplanningpanel: '',

		// Dates panel initially
		fecIniPastpanel: FEC_PAST_INI,
		fecEndPastpanel: FEC_PAST_END,
		fecIniFuturepanel: FEC_TODAY,
		fecEndFuturepanel: FEC_FUTURE_END,
		fecIniRequestedspanel: FEC_TODAY,
		fecEndRequestedspanel: FEC_REQPL_INI,
		fecIniPlanningpanel: FEC_TODAY,
		fecEndPlanningpanel: FEC_REQPL_INI,

		/**
		 * Instant panels data
		 */
		pastStatusCode: ['FI', 'IN'],
		futureStatusCode: ['IN', 'PL', 'AU', 'CO', 'AC', 'RQ'],
		requestedsStatusCode: ['PL', 'RQ', 'AC'],
		planningStatusCode: ['RQ', 'AC'],
		requestedsViewStatusMapCode: ['IN', 'AU', 'PA'], // visibles en el mapa pero no se pueden autorizar
		planningViewStatusMapCode: ['PL', 'IN', 'AU', 'PA'], // visibles en el mapa pero no se pueden planificar

		/**
		 * Panels
		 */
		activePanel: REQUESTEDS_PANEL_ID,
		activePanelOptions: null,
		panelParentActive: REQUESTEDS_PANEL_ID,
		dynamicPanels: [],
		dynamicPanelsId: [],

		/**
		 * Map Settings
		 */

		map: {
			legendExpanded: false,
			zoom: null,
			bbox: INITIAL_BOUNDS_KPIS
		}
	},

	mapvisits: {
		map: {
			zoom: null
		}
	},

	settingsPanel: {
		isOpened: {
			filters: false,
			layers: false
		}
	},
	allCompanyTypes: {
		TERMINAL: 1,
		MOORING: 2,
		TOWAGE: 3,
		STEVEDORE: 4,
		SHIPPING: 5,
		PILOTAGE: 6,
		PORTAUTHORY: 7,
		NAVIERA: 8,
		OTHER: 9,
		ADMIN: 10
	}
};
