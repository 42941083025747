<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'App'
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
</style>
